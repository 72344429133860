import React from 'react';
import { Form } from 'react-bootstrap';

const GuestInput = ({ guest, onGuestChange }) => {

    console.log('starting guestInput')
    console.log(guest)

    const handleInputChange = (event) => {
        console.log('hic was called')
        const { name, value } = event.target;
        onGuestChange(guest.id, name, value); // Directly sending the value selected by the user
    };

    // Console logs for debugging
    console.log(guest);
    console.log(guest.invitation_type.response_option_list);

    return (
        <Form.Group controlId={`guest-input-${guest.id}`}>
            <Form.Label>{`${guest.first_name}
                          ${guest.particle?guest.particle:''}
                          ${guest.last_name}`}</Form.Label>
            <Form.Select 
                name="chosen_response" 
                value={guest.chosen_response?.id || ''} // This ensures that the value matches the expected structure
                onChange={handleInputChange}
            >
                {Array.isArray(guest.invitation_type.response_option_list) && 
                 guest.invitation_type.response_option_list.map((option) => (
                     <option key={option.id} value={option.id}>{option.label}</option>
                ))}
            </Form.Select>
        </Form.Group>
    );
};


export default GuestInput;

